import React from 'react';

import './css/homepage.css'

export interface HomePageProps {

}

class HomePage extends React.Component<HomePageProps> {
    render() {
        return (
            <div className="container">
                <h3> Hi! I am Nico Ferraris </h3>
                <p> I am a Software Engineer at Amazon Robotics. </p>
                <p> I am a graduate of NYU's School of Engineering. </p>
                <p> Previously, I have interned at Amazon Robotics, Yahoo, Cambrian Intelligence, and the Digital Art
                    Experience. </p>
            </div>
        );
    }
}

export default HomePage;
