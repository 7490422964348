import React from 'react';

import './css/navigation.css';

export interface NavItemProps {
    readonly name: string,
    readonly address: string,
}

class NavItem extends React.Component<NavItemProps> {
    render() {
        return (
            <li> <a className="navLink" href={this.props.address}> {this.props.name} </a></li>
        );
    }
}

const DefaultNavigationProps: NavigationProps = {
    navLinks: [
        {name: 'Resume', address: '/Ferraris_Nicolai_2021.pdf'},
        {name: 'Github', address: 'https://www.github.com/nicolaifsf'},
        {name: 'LinkedIn', address: 'https://www.linkedin.com/in/nicolaiferraris/'},
        {name: 'Blog', address: 'https://medium.com/@nicolaifsf78'},
    ]
}

interface NavigationProps {
    navLinks: NavItemProps[]
}

class Navigation extends React.Component<NavigationProps> {

    render() {
        const listItems = this.props.navLinks.map((navItem) =>
            <NavItem name={navItem.name} address={navItem.address}/>
        );
        return (
            <div className="navigation">
                <ul className="navBar">
                    {listItems}
                </ul>
            </div>
        )
    }

}

export {
    Navigation,
    DefaultNavigationProps,
};
