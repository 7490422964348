import React from 'react';

import './css/header.css';

export interface HeaderProps {
    blurbs: string[]
}

const DefaultHeaderProps: HeaderProps = {
    blurbs: ['Software Engineer']
}

class Header extends React.Component<HeaderProps> {
    render() {
        const blurb = this.props.blurbs[Math.floor(Math.random() * this.props.blurbs.length)]
        return (
            <div className="blurb">
                <h1> Nico Ferraris </h1>
                <h3> {blurb} </h3>
            </div>
        );
    }
}

export {
    Header,
    DefaultHeaderProps
};
